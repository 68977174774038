/* ==========================================================================
Components
  > Article Listing
========================================================================== */

// Prevents aspect ratio issues

@mixin t-restricted-video {
  grid-template-columns: repeat(3, minmax(0, 1fr));

  &[data-listing__type='video'] {
    .c-restricted--video {
      padding: 20px;

      h2 {
        font-size: 18px;
        margin-bottom: 4px;
      }
    }
  }
}

.c-article__listing {
  list-style: none;
  margin: 0;
}

// In main article

.c-article--main {
  .c-article__listing {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 40px 0;

    &[data-listing__type='card'] {
      gap: var(--gutter);
    }

    // 2 cols

    @include bp-small-medium {
      gap: 48px var(--gutter);

      &:not([data-listing__type='video']) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    // Edge Case: For 3 col listings that return only 3 results, make first listing is full width within these widths

    @media (min-width: 540px) and (max-width: 959px) {
      &[data-listing__cols='3'][data-listing__count='3'] {
        li:first-child {
          grid-area: 1/1/2/3;
        }
      }
    }

    // Prevents aspect ratio issues

    @include bp-medium {
      &[data-listing__type='video'] {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    // 3 col

    @include bp-medium-large {
      &[data-listing__cols='3'] {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      // 4 col - check on retina display

      &[data-listing__cols='4'] {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }

    @media (min-width: 960px) and (max-width: 1140px) {
      &[data-listing__cols='3'] {
        @include t-restricted-video;
      }
    }

    // 4 col listing

    @include bp-xlarge {
      &[data-listing__cols='4'] {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }

    // 2 cols - 8 / 4 (Featured)

    &.c-article__listing--featured {
      @include bp-small-medium {
        .c-article__listing__featured {
          grid-area: 1 / 1 / 2 / 3;
        }
      }

      @include bp-medium-large {
        row-gap: var(--gutter);
        grid-template: 1fr 1fr / 67.41214057507987fr 32.58785942492013fr;

        .c-article__listing__featured {
          grid-area: 1 / 1 / 3 / 2;
        }

        article,
        figure {
          height: 100%;
        }

        .c-link--cover:hover + figure img {
          opacity: 0.48;
        }

        .u-aspect {
          margin: 0;
          padding-bottom: 0;
          background-color: var(--color--primary--dark);

          img {
            position: relative;
            transition: var(--global__transition);
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(
              to bottom,
              rgba(7, 15, 24, 0) 0%,
              rgba(7, 15, 24, 0.66) 100%
            );
            width: 100%;
            height: 50%;
          }
        }

        .c-article__copy {
          position: absolute;
          bottom: 24px;
          left: 24px;
        }
      }
    }

    h2 {
      margin-bottom: 8px;
    }

    // Vertical - e.g. news-listing.html

    &[data-listing__cols='1']:not([data-listing__width='100']) {
      .c-figure__hold {
        flex: 0 0 auto;
        max-width: 100%;
      }

      .c-article__copy {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;

        @include bp-small-medium {
          padding: 24px 24px 24px 0;
        }
      }

      @include bp-small-medium {
        &[data-listing__type='sort-delete'] {
          .c-article__copy {
            padding: 0;
          }
        }
      }

      @include bp-small-medium {
        .c-figure__hold {
          margin-right: var(--gutter);
          width: 50%;
          max-width: 300px;

          @include bp-medium {
            width: 300px;
          }
        }

        .c-article--grid {
          display: flex;

          figure {
            margin-bottom: 0;
          }
        }
      }

      @include bp-small-medium {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
      }
    }

    [data-target-restricted='true'] {
      h2 a {
        display: flex;
        gap: 16px;
      }

      .fa-lock {
        position: relative;
        top: 8px;
        font-size: 14px;
        width: 24px;
        text-align: center;
        margin-left: auto;
        flex-shrink: 0;
      }
    }

    &[data-listing__width='100'] {
      grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
  }

  // Sidebar

  &.l8 {
    .c-article__listing {
      &[data-listing__cols='3'] {
        @media (min-width: 960px) {
          @include t-restricted-video;
        }

        @include bp-large {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include bp-xlarge {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }

      &[data-listing__cols='4'] {
        @include bp-large {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
    }
  }
}

.c-article__heading {
  .c-link--cover {
    display: flex;
    gap: 16px;

    .fa-lock {
      font-size: 20px;
      margin-left: auto;
      margin-top: 8px;
    }
  }
}

// Grid

.c-article--grid {
  margin-bottom: 0;

  figure {
    border-radius: var(--global__radius);
    margin-bottom: 20px;

    [class^='fa-'] {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      color: #fff;
      font-size: 40px;
      z-index: 2;
      opacity: 0;
    }

    iframe {
      position: relative;
      z-index: 3;
    }
  }

  img {
    border-radius: var(--global__radius);
    margin-bottom: 0;
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin-bottom: 10px;
  }

  .h5 {
    margin-bottom: 8px;
  }

  p {
    margin: 8px 0 0;
  }

  .fal {
    width: 24px;
    text-align: center;
  }
}

// Divided e.g. Search Result / Recent Articles

.c-article--divided {
  &:not(:last-child) {
    margin-bottom: var(--wrapper__padding--minor);
    padding-bottom: var(--wrapper__padding--minor);
    border-bottom: 1px solid var(--color--grey--light);
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

// Card

[data-listing__type='card'] {
  article {
    position: relative;
    background-color: #fff;

    @include bp-small-medium {
      height: 100%;
    }

    .c-article__copy {
      padding: 0 24px 24px 24px;
    }

    .c-link--cover {
      &:after {
        border-radius: var(--global__radius);
      }

      &:hover {
        text-decoration: none;

        &:after {
          @include shadow(2);
        }
      }
    }
  }
}

/* ==========================================================================
Icon
========================================================================== */

[data-listing__icon='true'] {
  figure {
    overflow: hidden;

    // Overlay placeholder

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--global__radius);
      opacity: 0.72;
      z-index: 1;
    }
  }

  // Icon

  li:hover {
    [class^='fa-'] {
      opacity: 1;
    }

    // Green default (override with li data-listing__theme="theme-name" ) See 'Themes' below.

    figure:after {
      background-color: var(--color--sector--junior-cycle);
    }
  }
}

/* ==========================================================================
Dots
========================================================================== */

[data-listing__dots='true'] {
  h2 {
    padding-left: 24px;

    // Placeholder

    &:before {
      content: '';
      display: inline-flex;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      left: 24px;
      transform: translateY(11px);
    }
  }
}

/* ==========================================================================
Themes
========================================================================== */

// Early Childhood

[data-listing__theme='early-childhood'] {
  &:hover {
    figure:after {
      background-color: var(--color--sector--early-childhood) !important;
    }
  }

  [data-listing__dots='true'] & h2:before {
    background-color: var(--color--sector--early-childhood);
  }
}

// Primary

[data-listing__theme='primary'] {
  &:hover {
    figure:after {
      background-color: var(--color--sector--primary) !important;
    }
  }

  [data-listing__dots='true'] & h2:before {
    background-color: var(--color--sector--primary);
  }
}

// Junior Cycle

[data-listing__theme='junior-cycle'] {
  &:hover {
    figure:after {
      background-color: var(--color--sector--junior-cycle) !important;
    }
  }

  [data-listing__dots='true'] & h2:before {
    background-color: var(--color--sector--junior-cycle);
  }
}

// Senior Cycle

[data-listing__theme='senior-cycle'] {
  &:hover {
    figure:after {
      background-color: var(--color--sector--senior-cycle) !important;
    }
  }

  [data-listing__dots='true'] & h2:before {
    background-color: var(--color--sector--senior-cycle);
  }
}
